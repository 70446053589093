// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bJ_kC";
export var caseStudyBackground__lineColor = "bJ_kz";
export var caseStudyHead = "bJ_kt";
export var caseStudyHead__imageWrapper = "bJ_kv";
export var caseStudyProjectsSection = "bJ_kD";
export var caseStudyQuote__bgLight = "bJ_kK";
export var caseStudyQuote__bgRing = "bJ_kx";
export var caseStudyVideoReview = "bJ_k9";
export var caseStudyVideoReview__bgRing = "bJ_lb";
export var caseStudyVideo__ring = "bJ_kN";
export var caseStudy__bgDark = "bJ_ks";
export var caseStudy__bgLight = "bJ_kr";
export var caseStudy__bgLightReverse = "bJ_k8";